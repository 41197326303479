a,
a:hover {
  color: #000000;
}
h1,
h2,
h3,
h4,
h5,
h6,
.navbar-brand,
.card-title {
  font-family: "Playfair Display", serif;
  font-weight: bold;
}

span,
p {
  font-family: "Inter", serif;
  font-size: 18px;
}

.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid;
  line-height: 0.1em;
  margin: 40px 0;
  color: #868686;
}

.divider span {
  background: #faf7f1;
  padding: 0 10px;
}

.subtext {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.form-control {
  border-color: #868686;
}
.btn-custom,
.btn-custom:hover {
  background-color: #000000;
  color: #ffffff;
}
.btn-other,
.btn-other:hover {
  background-color: #ffffff;
  color: #212529;
  border-color: #212529;
}
.btn-outline {
  border-color: #000000;
  color: #000000;
  background-color: #ffffff;
}
.btn-outline:hover {
  background-color: #000000;
  color: #ffffff;
}

.btn-delete {
  background-color: #e3b3b3;
  color: #ffffff;
  border: 0px;
  font-size: 1em;
}
.btn-delete:hover {
  background-color: #e34444;
  color: #ffffff;
}

.ladle-header {
  font-size: 1.5em;
}

.progress-bar {
  background-color: #000000;
}

body {
  font-family: "Inter", sans-serif;
}
