.delivery-time-picker > div > .btn-primary {
  background-color: #ffffff;
  color: black;
  border-color: #131313;
}

.delivery-time-picker > div > .btn-primary.dropdown-toggle,
.delivery-time-picker > div > .btn-primary:focus.dropdown-toggle {
  background-color: rgb(240, 240, 240);
  color: black;
  border-color: #131313;
}

.delivery-time-picker > div > .dropdown-menu {
  height: 200px;
  overflow-y: auto;
}

.delivery-time-picker-toast {
  position: absolute;
  top: -20px;
}
